import { action, makeAutoObservable, runInAction } from "mobx";
import UiAlert from "../../models/uiAlert";
import UsersStore from "../UsersStore";
import NotificationsStore from "../NotificationsStore";
import DutyPhonesStore from "../DutyPhonesStore";
import usersApi from "../../services/userApi";
import notificationsApi from "../../services/notificationsApi";
import dutyPhonesApi from "../../services/dutyPhoneApi";
import { confirmModal } from "@services/syncedModal";
import { AxiosError } from "axios";
import PtsStore from "../PtsStore";
import ptsApi from "../../services/ptsApi";
import AircraftGroup from "../../models/aircraftGroup";
import { difference } from "lodash";
import rootApi from "../../services/rootApi";
import { IDutyPhonesStore } from "../DutyPhonesStore/index.types";
import { INotificationsStore } from "../NotificationsStore/index.types";
import { IPtsStore } from "../PtsStore/index.types";
import { IUsersStore } from "../UsersStore/index.types";
import { IHomeStore } from "./index.types";
import { IRootStore } from "../RootStore/index.types";
import di, { getConfig } from "@di";
import { asValue } from "awilix";
import { MessageDescriptor } from "react-intl";
import { TAirlineData } from "@models/AirlineData";

export type { IHomeStore } from "./index.types";

export class HomeStore implements IHomeStore {
  ready = false;

  usersStore: IUsersStore;
  notificationsStore: INotificationsStore;
  dutyPhonesStore: IDutyPhonesStore;
  ptsStore: IPtsStore;

  uiAlert: UiAlert | null = null;

  aircrafts: AircraftGroup[] = [];
  airlines: TAirlineData[] = [];

  constructor(public root: IRootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.usersStore = new UsersStore(this, usersApi);
    this.notificationsStore = new NotificationsStore(this, notificationsApi);
    this.dutyPhonesStore = new DutyPhonesStore(this, dutyPhonesApi);
    this.ptsStore = new PtsStore(this, ptsApi);

    this._init();
  }

  get notificationGroups(): string[] {
    const { filterNotificationGroups } = getConfig();
    return this._getFilteredGroups(filterNotificationGroups);
  }

  get ptsGroups(): string[] {
    const { filterPtsGroups } = getConfig();
    return this._getFilteredGroups(filterPtsGroups);
  }

  setUIAlert(text?: string, type: UiAlert["type"] = "info") {
    if (text) {
      this.uiAlert = { text, type };
    } else {
      this.uiAlert = null;
    }
  }

  // TODO make it inside react app, not as separate react app
  async showConfirmModal(text: MessageDescriptor, values: any) {
    const confirmed = await confirmModal(text, values);
    if (!confirmed) {
      return Promise.reject();
    }
  }

  handlePhoneError(e: unknown, isWelcomeMessage = true) {
    const status = (e as AxiosError).response?.status;
    if (status === 422) {
      this.setUIAlert("Please enter a correct phone number", "error");
      throw e;
    } else if (status === 400) {
      this.setUIAlert(
        `SMS${isWelcomeMessage && " welcome"} message could not be delivered`,
        "error",
      );
      throw e;
    }
  }

  async initAircrafts() {
    if (this.aircrafts.length) {
      return this.aircrafts;
    }

    return notificationsApi
      .getAircrafts()
      .then(action((items) => (this.aircrafts = items)));
  }

  async initAirlines() {
    if (this.airlines.length) {
      return this.airlines;
    }

    return notificationsApi.getAirlines().then(
      action((items) => {
        this.airlines = items;

        return items;
      }),
    );
  }

  private async _init() {
    const token = await rootApi.getFileToken();
    const config = await rootApi.getConfig(token);

    console.debug("Configuration loaded.");

    // @ts-ignore
    const configVersion = config.configVersion;
    const appVersion = process.env.FE_CONFIG_VERSION;

    if (appVersion !== configVersion) {
      console.warn("Frontend configuration version mismatch!", {
        configVersion,
        appVersion,
      });
    }

    di.register({ config: asValue(config) });

    runInAction(() => {
      this.ready = true;
    });
  }

  private _getFilteredGroups(listToFilter: string[] = []): string[] {
    const { resourcesWithoutType } = this.root.authStore;
    return difference(resourcesWithoutType, ["admin_api", ...listToFilter]);
  }
}
