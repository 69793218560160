import {
  camelCase,
  capitalize,
  merge,
  mergeWith,
  snakeCase,
  uniq,
} from "lodash";
import {
  EMPTY,
  FULL_FLIGHTS_ONLY,
  INBOUNDS_ONLY,
  IncidentConfig,
  SET,
  TOW_OFF,
  TOW_ON,
  TurnaroundPointInTime,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { PtsEventScheduleOptions } from "@models/pts";
import { extractLocalized } from "@i18n/utils";
import { getConfig } from "@di";
import { notificationTriggerLabels } from "@i18n/messages";
import { IntlShape } from "react-intl";

export function camelCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [camelCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? camelCaseKeys(obj[field])
          : obj[field],
    }),
    {},
  );
}

export function snakeCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (scObj, field) => ({
      ...scObj,
      [snakeCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? snakeCaseKeys(obj[field])
          : obj[field],
    }),
    {},
  );
}

// TODO not good that regular we have label as formattedMessage and items that are not localized
// TODO dont use intl directly in this function
export const getTriggerLabelData = (
  n: UnifiedIncidentConfig,
  intl: IntlShape,
) => {
  const trigger = n.data.detectedAttributes[0];
  return {
    label:
      trigger.type === "event"
        ? formatEventName(trigger.objectName, trigger.eventName)
        : parseTurnParam(trigger.parameterName),
    items: [intl.formatMessage(notificationTriggerLabels[n.data.trigger])],
  };
};

export const getTimerLabelData = (n: TurnaroundPointInTime | null) => {
  if (!n) {
    return {
      label: "",
      items: [],
    };
  }

  const { timeShift, turnaroundAttribute } = n;
  return {
    label:
      turnaroundAttribute.type === "event"
        ? formatEventName(
            turnaroundAttribute.objectName,
            turnaroundAttribute.eventName,
          )
        : parseTurnParam(turnaroundAttribute.parameterName),
    items: [`${Math.round(timeShift / 60)}m`],
  };
};

export const getSelectedTypes = (
  included: string[],
  excluded: string[],
): {
  filterType: "includes" | "excludes";
  selectedTypes: string[];
} => {
  if (included.length) {
    return {
      filterType: "includes",
      selectedTypes: [...included],
    };
  } else if (excluded.length) {
    return {
      filterType: "excludes",
      selectedTypes: [...excluded],
    };
  }

  return {
    filterType: "includes",
    selectedTypes: [],
  };
};

export const getStatusValue = ({
  inboundFlightStatus,
  outboundFlightStatus,
}: Pick<IncidentConfig, "inboundFlightStatus" | "outboundFlightStatus">) => {
  if (inboundFlightStatus === SET && outboundFlightStatus === EMPTY) {
    return TOW_OFF;
  }
  if (inboundFlightStatus === EMPTY && outboundFlightStatus === SET) {
    return TOW_ON;
  }
  if (inboundFlightStatus === SET && outboundFlightStatus === null) {
    return INBOUNDS_ONLY;
  }
  if (inboundFlightStatus === SET && outboundFlightStatus === SET) {
    return FULL_FLIGHTS_ONLY;
  }

  return "";
};

export const customMerge = (...args: Parameters<typeof merge>) => {
  // Replace array by target value instead of merge
  const customizer = (objValue: any, targetValue: any) => {
    if (Array.isArray(objValue)) {
      return targetValue;
    }
  };

  mergeWith(...args, customizer);
};

export const snakeToHumanCase = (str: string) =>
  // eslint-disable-next-line no-useless-escape
  capitalize(str.replace(/\_/g, " "));

export const formatObjectName = (objectName: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectName === objectName,
  );
  return extractLocalized(eventInfo?.objectLabel, objectName);
};

export const formatObjectPosition = (position: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectPosition === position,
  );
  return extractLocalized(eventInfo?.objectPositionLabel, position);
};

export const formatObjectType = (type: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find((e) => e.objectType === type);
  return extractLocalized(eventInfo?.objectTypeLabel, type);
};

export const formatEventName = (objectName: string, eventName: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectName === objectName && e.eventType === eventName,
  );
  return `${extractLocalized(eventInfo?.objectLabel, objectName)} ${extractLocalized(eventInfo?.eventLabel, eventName).toLowerCase()}`;
};

export function parseTurnParam(
  parameterName: PtsEventScheduleOptions["referencePoint"],
) {
  const config = getConfig();
  const paramInfo = config.incidentTurnaroundParams.find(
    (p) => p.id === parameterName,
  )?.label;
  return extractLocalized(paramInfo, parameterName);
}

export function getUniqPropValues<T extends Record<string, any>>(
  items: T[],
  query: Partial<T>,
  prop: keyof T,
): string[] {
  return uniq(
    items
      .filter((i) =>
        Object.entries(query).every(
          ([key, value]) => !value || i[key] == value,
        ),
      )
      .map((e) => e[prop] || ""),
  ).filter(Boolean);
}

export const formatNotificationGroupLabel = (group: string) =>
  group.split("::")[0] || group;
